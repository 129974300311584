import { IMAGE_FRAGMENT, IImage } from './image'

export const ACCOUNT_FRAGMENT = `
  sys {
    id
  }
  firstName
  lastName
  handle
  email
  image {
    ${IMAGE_FRAGMENT}
  }
  dob
  stytchId
  isVerified
  favoriteProductsCollection {
    items {
      sys {
        id
      }
      bastaId
      name
      slug
    }
  }
`

export interface IProduct {
  __typename: 'Product'
  sys: {
    id: string
  }
  bastaId: string
  name: string
  slug: string
}

export interface IAccount {
  sys: {
    id: string
  }
  dni?: string
  firstName?: string
  lastName?: string
  handle: string
  email: string
  image?: IImage
  dob?: string
  stytchId: string
  isVerified?: boolean | false
  favoriteProducts?: {
    items: IProduct[]
  }
  veriffStatus?: string
  veriffProcessId?: string
}
