import { IMAGE_FRAGMENT, IImage } from './image'

export const MENU_FRAGMENT = `
menuCollection(limit: 1)  {
  items {
    __typename
    logo {
      ${IMAGE_FRAGMENT}
    }
    headerLinksCollection(limit: 20) {
      items {
        name
        slug
        rightPosition
      }
    }
    footerConfigCollection(limit: 20) {
      items {
        title
        type
        linksCollection {
          items {
            name
            slug
            rightPosition
            isALink
          }
        }
      }
    }
    mainFooterCollection(limit: 20) {
      items {
        name
        slug
        rightPosition
        isALink
      }
    }
  }
}
`

export interface IText {
  __typename: 'document'
  json
}

export interface IMenuLink {
  __typename: 'menuLink'
  name: string
  slug: string
  rightPosition: boolean
  isALink: boolean
}

export interface IMenuColumn {
  __typename: 'column'
  title: string
  type: string
  linksCollection: {
    items: IMenuLink[]
  }
}

export interface IMenu {
  __typename: 'menu'
  logo: IImage
  headerLinksCollection: {
    items: IMenuLink[]
  }
  footerConfigCollection: {
    items: IMenuColumn[]
  }
  mainFooterCollection: {
    items: IMenuLink[]
  }
}
