/** @jsxImportSource theme-ui */
import React from 'react'
import Link from '~/components/Generic/Link'
import Logo from '~/assets/images/logos/newLogoBlack.svg'

const NavMenuLogoBlack: React.FC = () => {
  return (
    <Link
      href="/"
      sx={{
        flexShrink: 0,
        '& svg': { width: '120px', height: '33px' },
      }}
      aria-label="Home"
    >
      <Logo />
    </Link>
  )
}

export default NavMenuLogoBlack
