/** @jsxImportSource theme-ui */
import React, { useEffect, useState } from 'react'
import { Box } from 'theme-ui'
import NavMenu from '~/components/Nav/Menu'
import NavMenuDesktop from '~/components/Nav/MenuDesktop'
import styles from './styles.module.css'
import { getMenu } from 'graphql-cms/queries/menu'
import { IMenu, IProductAsset } from 'graphql-cms/types'
import { getMainImageAllProducts } from 'graphql-cms/queries/product'

interface Props {
  hasHero: boolean | null
}

export const NavBar: React.FC<Props> = ({ hasHero }) => {
  const [menuConfig, setMenuConfig] = useState<IMenu | null>(null)
  const [productsImage, setProductsImage] = useState<IProductAsset[]>([])

  useEffect(() => {
    getMenu().then((menu) => setMenuConfig(menu))
    getMainImageAllProducts().then((assets) =>
      setProductsImage(assets as IProductAsset[])
    )
  }, [])

  return (
    <Box
      as="nav"
      role="navigation"
      className={styles.navBar}
      sx={{
        display: !hasHero ? 'contents !important' : '',
      }}
    >
      <NavMenuDesktop
        {...menuConfig}
        hasHero={hasHero}
        productsAssets={productsImage}
      />
      <NavMenu
        {...menuConfig}
        hasHero={hasHero}
        productsAssets={productsImage}
      />
      <hr
        sx={{
          display: !hasHero ? 'block' : 'none',
          marginLeft: ['10px', '10px', '64px'],
          marginRight: ['10px', '10px', '64px'],
          marginBottom: 0,
          height: '1px',
          border: 'none',
          borderBottom: '1px solid #B5B5B5',
        }}
      />
    </Box>
  )
}

export default NavBar
