import { IAccount, IProduct } from 'graphql-cms/types'
import React from 'react'

export interface IAccountContext {
  refreshAccount: () => Promise<void>
  getFavs: () => IProduct[]
  account: IAccount
  setAccount: (account: IAccount) => void
  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void
  logout: () => void
  isVerified: boolean
  veriffStatus: string
  veriffProcessId?: string
}

const AccountContext = React.createContext<IAccountContext | null>(null)
AccountContext.displayName = 'AccountContext'

export function useAccountContext() {
  const context = React.useContext(AccountContext)
  if (!context) {
    throw new Error(`useAccount must be used within a AccountProvider`)
  }
  return context
}

export default AccountContext
