import { IAnnouncementsCollection } from './announcement-bar'

export const SCROLLING_BAR_FRAGMENT = `
  scrollingBarCollection(limit: 1)  {
    items {
      __typename
      name
      slug
      travelSpeed
      announcementsCollection(limit: 15) {
        items {
          __typename
          message
          url
          startDate
          endDate
        }
      }
    }
  }
`

export interface IScrollingBar {
  __typename: 'scrollingBar'
  name: string
  slug: string
  travelSpeed: string
  announcementsCollection: {
    items: IAnnouncementsCollection[]
  }
}
