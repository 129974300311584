import React from 'react'
import styles from './styles.module.css'
import { useTranslate } from '~/hooks'
import Toast from './Toast'
import { Box, Flex } from 'theme-ui'
import LogoSuccess from '~/assets/images/icons/modal/check.svg'
import LogoError from '~/assets/images/icons/modal/danger.svg'
import LogoWarning from '~/assets/images/icons/modal/warning.svg'
import { useCustomNotificationContext } from '~/context/custom-notification'

export type INotification = {
  message: string
  type?: 'success' | 'error' | 'warning'
  modalType?: 'modal' | 'toast'
  timer?: number
  title?: string
  onContinue?: () => void
  onClose?: () => void
}

interface Props {}

const globalConfig = {
  success: {
    icon: <LogoSuccess />,
    title: 'bid.successful',
    btn: 'bid.done',
  },
  error: {
    icon: <LogoError />,
    title: 'bid.error',
    btn: 'bid.retry',
  },
  warning: {
    icon: <LogoWarning />,
    title: 'bid.warning',
    btn: 'bid.continue',
  },
}

export const CustomNotification: React.FC<Props> = () => {
  const translate = useTranslate()
  const { showNotification, setShowNotification, notification } =
    useCustomNotificationContext()
  const { onContinue, onClose } = notification

  if (!showNotification) return <></>

  const {
    type = 'success',
    modalType = 'modal',
    timer = 5000,
    message,
    title,
  } = notification

  const { icon, title: defaultTitle, btn } = globalConfig[type]

  const finalTitle = title || defaultTitle

  if (modalType === 'toast')
    return (
      <Toast
        setNotification={setShowNotification}
        timer={timer}
        message={message}
        type={type}
      />
    )

  return (
    <Box className={styles.modal}>
      <Box className={[styles.modal_div, styles[type]].join(' ')}>
        <Box className={styles.modal_div_header}>
          <span
            onClick={() => {
              if (onClose) {
                onClose()
              }
              setShowNotification(false)
            }}
            className={styles.modal_close}
          >
            {translate('bid.close')}
          </span>
        </Box>
        <Flex className={styles.modal_div_body}>
          <Box className={styles.modal_div_icon}>{icon}</Box>
          <h3>{translate(finalTitle)}</h3>
          <p style={{ padding: '0px 15px 0px 15px' }}>{message}</p>
          <span
            onClick={() => {
              if (onContinue) {
                onContinue()
              }
              setShowNotification(false)
            }}
            className={styles.modal_div_done}
          >
            {translate(btn)}
          </span>
        </Flex>
      </Box>
    </Box>
  )
}

export default CustomNotification
