/** @jsxImportSource theme-ui */
import React from 'react'
import Link, { LinkProps } from '~/components/Generic/Link'
import theme from '~/theme'

type Props = Omit<LinkProps, 'children' | 'href'> & {
  page: {
    name: string
    slug: string
  }
}

const NavMenuLink: React.FC<Props> = ({ page, ...props }) => {
  return (
    <Link
      href={`/${page.slug}/`}
      sx={{
        variant: 'text.navLink',
        color: `${theme.colors.black}`,
        fontFamily: 'Nanum Gothic Bold',
        fontSize: '12px',
        fontStyle: 'normal',
        lineHeight: 'normal',
        width: ['100%', 'auto'],
        padding: ['0 1rem', '0'],
        '&:last-child': {
          borderBottom: ['1px solid', 'none'],
        },
      }}
      {...props}
    >
      {page.name}
    </Link>
  )
}

export default NavMenuLink
