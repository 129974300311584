import { NextRouter } from 'next/router'
import parse from 'url-parse'

const hasURLSearchParams =
  typeof window !== 'undefined' && typeof URLSearchParams !== 'undefined'

export const getParam = (key: string) => {
  if (!hasURLSearchParams) return null
  const searchParams = new URLSearchParams(window.location.search)
  return searchParams.get(key)
}

export const getLocalUrl = () => {
  return process.env.LOCAL_URL
}

export const getApiUrl = (path: string) => {
  return parse(`${getLocalUrl()}${path}`).toString()
}

export const parseUrl = (path: string) => {
  return parse(path).toString()
}

export const allowCors = (fn) => async (req, res) => {
  res.setHeader('Access-Control-Allow-Credentials', true)
  res.setHeader('Access-Control-Allow-Origin', '*')
  res.setHeader(
    'Access-Control-Allow-Methods',
    'GET,OPTIONS,PATCH,DELETE,POST,PUT'
  )
  res.setHeader(
    'Access-Control-Allow-Headers',
    'X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version'
  )
  if (req.method === 'OPTIONS') {
    res.status(200).end()
    return
  }
  return await fn(req, res)
}

export const delay = async (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const redirectShadow = (router: NextRouter, pathname: string) => {
  return router.push({ pathname }, undefined, { shallow: true })
}

/** @ts-expect-error args*/
export const fetcher = (...args) => fetch(...args).then((res) => res.json())

export const fetcherPost = body => url => fetch(url, {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
  },
  body,
}).then((res) => res.json())
