import { ISales } from 'basta-ai/types'
import { getApiUrl } from '../url'

export enum enumResponseStatus {
  SUCCESS = 'success',
  ERROR = 'error'
}

export const getSaleById = async (saleId: string) => {
  let data = {}
  if (saleId) {
    try {
      const _response = await fetch(getApiUrl('/api/basta/get-sale/'), {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          saleId,
        }),
      })

      if (_response.status === 200) {
        const response = await _response.json()
        data = response.data
      }
    } catch (error) {
      console.log(error)
    }
  }

  return data as ISales
}

export const getAllSales = async () => {
  let data = []
  try {
    const _response = await fetch(getApiUrl('/api/basta/all-sales/'), {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (_response.status === 200) {
      const response = await _response.json()
      data = response.data
    }
  } catch (error) {
    console.log(error)
  }

  return data as ISales[]
}

export const placeABid = async ({ userId, saleId, itemId, amount, type }) => {
  let resp = {}
  try {
    const _response = await fetch(getApiUrl('/api/basta/place-a-bid/'), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userId,
        saleId,
        itemId,
        amount,
        type,
      }),
    })

    const response = await _response.json()
    resp = response
  } catch (error) {
    resp = {
      status: enumResponseStatus.ERROR,
      ...error,
    }
  }

  return resp
}

export const getSaleByItemId = async (bastaId: string) => {
  let data = {}
  if (bastaId) {
    try {
      const _response = await fetch(
        getApiUrl('/api/basta/get-sale-by-item-id/'),
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            bastaId,
          }),
        }
      )

      if (_response.status === 200) {
        const response = await _response.json()
        data = response.data
      }
    } catch (error) {
      console.log(error)
    }
  }

  return data as ISales
}
