import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import { useTranslate } from '~/hooks'
import { Box, Flex, Link } from 'theme-ui'
import Cookies from 'universal-cookie'

enum enumCookieConsent {
  AGREE = "agree",
  DECLINE = "decline"
}

export const CookieConsent: React.FC = () => {
  const translate = useTranslate()
  const cookies = new Cookies()
  const [hasCookieConsentAgree, setHasCookieConsentAgree] = useState(true)

  const handleAgree = () => {
    const expires = new Date()
    expires.setDate(expires.getDate() + 15)
    cookies.set('cookie_consent', enumCookieConsent.AGREE, { path: '/', expires })
    setHasCookieConsentAgree(true)
  }

  const handleDecline = () => {
    const expires = new Date()
    expires.setDate(expires.getDate() + 1)
    cookies.set('cookie_consent', enumCookieConsent.DECLINE, { path: '/', expires })
    setHasCookieConsentAgree(true)
  }

  useEffect(() => {
    const hasCookieConsentActive = cookies.get('cookie_consent')
    if (!hasCookieConsentActive) setHasCookieConsentAgree(cookies.get('cookie_consent') === enumCookieConsent.AGREE)
  }, [])

  return (
    <Box
      className={styles.modal}
      sx={{
        display: hasCookieConsentAgree ? 'none' : 'flex',
      }}
    >
      <Box className={styles.modal_div}>
        <Flex
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <span className={styles.title}>
            {translate('cookie_consent.cookie_policy')}
          </span>
          <span onClick={handleDecline} className={styles.close}>
            {translate('cookie_consent.close')}
          </span>
        </Flex>
        <Flex className={styles.modal_div_body}>
          <p>
            {translate('cookie_consent.text', {
              cookie_policy: (
                <Link>{translate('cookie_consent.cookie_policy')}</Link>
              ),
            })}
          </p>
          <Flex sx={{ width: '100%', textAlign: 'center' }}>
            <span onClick={handleAgree} className={styles.agree}>
              {translate('cookie_consent.agree')}
            </span>
            <span onClick={handleDecline} className={styles.decline}>
              {translate('cookie_consent.decline')}
            </span>
          </Flex>
        </Flex>
      </Box>
    </Box>
  )
}

export default CookieConsent
