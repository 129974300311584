import { Address, useUser } from '@chordcommerce/react-autonomy'
import { UpdateAddressInput } from '@chordcommerce/react-autonomy/dist/redux/actions'
import { useEffect, useState } from 'react'

const useAddress = () => {
  const {
    user,
    modifyUserAddressBook,
    loadUserAddressBook,
    removeFromUserAddressBook,
  } = useUser()
  const [addressList, setAddressList] = useState<Address[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    loadUserAddressBook()
  }, [])

  useEffect(() => {
    if (!user.addressBook) return
    const hasDefaultAddress = user.addressBook.find((a) => a.default)

    if (!hasDefaultAddress && user.addressBook.length) {
      setAddressToDefault(user.addressBook[0].id)
    }

    setAddressList(user.addressBook)
    setLoading(false)
  }, [user.addressBook])

  const setAddressToDefault = async (addressId) => {
    setLoading(true)
    const addressToUpdate = {
      ...user.addressBook.find((a) => a.id === addressId),
    }

    delete addressToUpdate.country
    delete addressToUpdate.state
    addressToUpdate.default = true

    await modifyUserAddressBook(addressToUpdate)
    await loadUserAddressBook().catch(() => {})
    setLoading(false)
  }

  const addNewAddress = async (newAddress: UpdateAddressInput) => {
    setLoading(true)
    await modifyUserAddressBook(newAddress)
    await loadUserAddressBook()
    setLoading(false)
  }

  const handleRemoveAddress = async (addressId: number) => {
    setLoading(true)
    await removeFromUserAddressBook(addressId)
    await loadUserAddressBook()
  }

  return {
    setAddressToDefault,
    addressList,
    addNewAddress,
    removeAddress: handleRemoveAddress,
    loading,
  }
}

export default useAddress
