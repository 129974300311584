/** @jsxImportSource theme-ui */
import React from 'react'
import { Flex } from 'theme-ui'
import NavMenuDesktopLink from './Link'
import NavMenuDesktopLogo from './Logo'
import { IMenu, IProductAsset } from 'graphql-cms/types'
import AuthLink from '~/components/Nav/AuthLink'
import DrawerCart from '~/components/Nav/DrawerCart'

const NavMenuDesktop: React.FC<
  IMenu & { hasHero: boolean | null; productsAssets: IProductAsset[] }
> = (menuConfig, productsAssets) => {
  const pages = menuConfig?.headerLinksCollection?.items || []
  const hasHero = menuConfig?.hasHero || false
  const leftLinks = pages.filter((_page) => _page.rightPosition === false)
  const width = '33%'

  return (
    <Flex
      sx={{
        justifyContent: 'center',
        width: '100%',
        display: ['none', 'none', 'flex'],
        padding: '0px 64px',
      }}
    >
      <Flex
        sx={{
          alignItems: 'center',
          padding: '0px 16px',
          width: '100%',
          transition: 'height .25s ease, box-shadow .25s ease',
          height: '89px',
          boxShadow: 'none',
          justifyContent: 'space-between',
        }}
      >
        <Flex sx={{ justifyContent: 'flex-start', gap: 40, width }}>
          {leftLinks.map((page) => (
            <NavMenuDesktopLink hasHero={hasHero} page={page} key={page.slug} />
          ))}
        </Flex>

        <Flex sx={{ justifyContent: 'center', width }}>
          <NavMenuDesktopLogo hasHero={hasHero} />
        </Flex>

        <Flex
          sx={{
            justifyContent: 'flex-end',
            alignItems: 'center',
            width,
            gap: 40,
          }}
        >
          <AuthLink hasHero={hasHero} />
          <DrawerCart hasHero={hasHero} productsAssets={productsAssets} />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default NavMenuDesktop
