import { IAccount } from 'graphql-cms/types'
import { getApiUrl } from '../url'

export const getOrCreateAccount = async ({
  id,
  email,
}: {
  id: string | number
  email: string
}): Promise<IAccount | null> => {
  let resp
  try {
    const _response = await fetch(getApiUrl('/api/ct/get-account/'), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id,
        email,
      }),
    })

    const response = await _response.json()
    resp = response.data
  } catch (error) {
    resp = {
      status: 'error',
      ...error,
    }
  }

  return resp
}

export const updateAFav = async ({
  accountId,
  productId,
  isFav,
}: {
  accountId: string
  productId: string
  isFav: boolean
}): Promise<IAccount | false> => {
  let resp
  try {
    const _response = await fetch(getApiUrl('/api/ct/update-fav/'), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        accountId,
        productId,
        isFav,
      }),
    })

    const response = await _response.json()
    resp = response.data
  } catch (error) {
    resp = {
      status: 'error',
      ...error,
    }
  }

  return resp
}

export const updateAccount = async (attributes): Promise<IAccount | false> => {
  let resp
  try {
    const _response = await fetch(getApiUrl('/api/ct/update-account/'), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(attributes),
    })

    const response = await _response.json()
    resp = response.data
  } catch (error) {
    resp = {
      status: 'error',
      ...error,
    }
  }

  return resp
}
