import React from 'react'
// import NotificationBar from '~/components/Notification/Bar'
import Footer from '~/components/Footer'
import Nav from '~/components/Nav'
import type { PropsOnlyChildren } from '~/types'
import AnnouncementBar from '../AnnouncementBar'
import CustomNotification from '~/components/Generic/CustomNotification'
import CustomNotificationProvider from '../../provider/CustomNotificationProvider'
import CookieConsent from '~/components/CookieConsent'

const Layout: React.FC<
  PropsOnlyChildren & {
    pageProps
  }
> = ({ children, pageProps }) => {
  const hasHero = pageProps?.page?.hero || false
  const isProductPage = pageProps?.product || false

  return (
    <CustomNotificationProvider>
      {pageProps?.page?.withHeader !== false && (
        <>
          <AnnouncementBar
            announcementBarConfig={pageProps.announcementBarConfig || []}
          />
          <Nav hasHero={hasHero} />
        </>
      )}
      <CustomNotification />
      <main
        role="main"
        sx={{
          position: 'inherit',
        }}
      >
        <>{children}</>
      </main>
      {pageProps?.page?.withFooter !== false && <Footer isProductPage={isProductPage !== false} />}
      <CookieConsent />
    </CustomNotificationProvider>
  )
}

export default Layout
