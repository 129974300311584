import { IMAGE_FRAGMENT, IImage } from './image'
import { OPTION_VALUE_FRAGMENT, IOptionValue } from './option-value'

export const RELATED_PRODUCT_FRAGMENT = `
  __typename

  ... on Product {
    bastaId
    isActive
    name
    slug
    shortDescription
    mainImage {
      ${IMAGE_FRAGMENT}
    }
    variantsCollection(limit: 1) {
      total
      items {
        name
        sku
        price
        regularPrice
        size
        optionValuesCollection(limit: 5) {
          items {
            ${OPTION_VALUE_FRAGMENT}
          }
        }
      }
    }
  }

  ... on Bundle {
    name
    slug
    masterSku
    shortDescription
    price
    regularPrice
    globalTradeItemNumber
    vendor
    mainImage {
      ${IMAGE_FRAGMENT}
    }
    productsCollection(limit: 1) {
      total
      items {
        __typename

        ... on Product {
          vendor
          productType {
            name
          }
          giftCard
          sample
          hidden

          variantsCollection(limit: 1) {
            items {
              globalTradeItemNumber
              size
              weight
              price
              name
              sku
            }
          }
        }

        ... on Variant {
          globalTradeItemNumber
          size
          weight
          price
          name
          sku
        }
      }
    }
  }
`

export interface IRelatedProductProduct {
  __typename: 'Product'
  bastaId: string | null
  isActive?: boolean | null
  name: string
  slug: string
  shortDescription: string | null
  mainImage: IImage
  variantsCollection: {
    total: number
    items: {
      name: string
      sku: string
      price: number
      regularPrice: number
      size: string
      optionValuesCollection: {
        items: IOptionValue[]
      } | null
    }[]
  }
}

export interface IRelatedProductBundle {
  __typename: 'Bundle'
  name: string
  slug: string
  masterSku: string
  shortDescription: string | null
  price: number
  regularPrice: number
  vendor: string | null
  globalTradeItemNumber: string | null
  mainImage: IImage
  productsCollection: {
    total: number
    items: (
      | {
          __typename: 'Product'
          vendor: string | null
          productType: {
            name: string
          } | null
          giftCard: boolean
          sample: boolean
          hidden: boolean
          variantsCollection: {
            items: {
              globalTradeItemNumber: string
              size: string
              weight: number
              price: number
              name: string
              sku: string
            }[]
          }
        }
      | {
          __typename: 'Variant'
          globalTradeItemNumber: string
          size: string
          weight: number
          price: number
          name: string
          sku: string
        }
    )[]
  }
}

export type IRelatedProduct = IRelatedProductProduct | IRelatedProductBundle
