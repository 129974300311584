import { useAuth, useUser } from '@chordcommerce/react-autonomy'
import type { IAccount, IProduct } from 'graphql-cms/types'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import AccountContext from '~/context/account'
import { getOrCreateAccount } from '~/utils/sdk-ct'

export const VERIFF_STATUS_ENUM = {
  PENDING: 'Pending',
  APPROVED: 'Approved',
  DENIED: 'Denied',
  EXPIRED: 'Expired',
  CANCELED: 'Canceled',
  ERROR: 'Error',
}

const AccountProvider = ({ children }) => {
  const [account, setAccount] = useState<IAccount | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isVerified, setIsVerified] = useState(false)
  const [veriffStatus, setVeriffStatus] = useState(VERIFF_STATUS_ENUM.PENDING)
  const { logout } = useAuth()
  const { loadUser, user } = useUser()
  const { locale } = useRouter()

  useEffect(() => {
    loadUser().catch((error) => console.error(error))
  }, [])

  useEffect(() => {
    if (user.data) refreshAccount()
  }, [user])

  const refreshAccount = async () => {
    const { id, email } = user.data
    if (id !== undefined && email !== undefined) {
      const _account = await getOrCreateAccount({ id, email })

      if (_account) {
        setAccount(_account)
        setIsVerified(_account?.isVerified?.['en-US'] || false)
        setVeriffStatus(
          VERIFF_STATUS_ENUM[_account?.veriffStatus?.[locale].toUpperCase()] ||
            VERIFF_STATUS_ENUM.PENDING
        )
        setIsLoading(false)
      }
    }
  }

  const handleLogout = () => {
    logout()
    setAccount(null)
  }

  const getFavs = () => {
    return (account?.favoriteProducts?.items || []) as IProduct[]
  }

  return (
    <AccountContext.Provider
      value={{
        getFavs,
        account,
        setAccount,
        refreshAccount,
        isLoading,
        setIsLoading,
        logout: handleLogout,
        isVerified,
        veriffStatus,
      }}
    >
      {children}
    </AccountContext.Provider>
  )
}

export default AccountProvider
