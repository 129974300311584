import { OrderBig, useCart, useCheckout } from '@chordcommerce/react-autonomy'
import { useEffect, useState } from 'react'
import CartContext from '~/context/cart'

const AccountProvider = ({ children }) => {
  const [ needsOpenCart, setNeedsOpenCart ] = useState(false)
  const { loadCart, addToCart, cart, emptyCart, removeFromCart, isLoaded, isFetching } = useCart()
  const { prepareCheckout } = useCheckout()

  useEffect(() => {
    loadCart().catch(error => console.error(error))
  }, [loadCart])


  const handleAddToCart = async ({ sku, quantity } : { sku: string, quantity: number}): Promise<OrderBig> => {
    const options = {}
    const updatedCart = await addToCart({
      sku,
      quantity,
      options
    })
    setNeedsOpenCart(true)
    return updatedCart
  }

  const handleBuyNow = async ({ sku, quantity } : { sku: string, quantity: number}): Promise<OrderBig> => {
    const options = {}
    await emptyCart()
    await addToCart({
      sku,
      quantity,
      options
    })
    const checkoutResponse = await prepareCheckout()
    return checkoutResponse
  }

  return (
    <CartContext.Provider
      value={{
        buyNow: handleBuyNow,
        addToCart: handleAddToCart,
        loadCart,
        removeFromCart,
        isLoaded, 
        isFetching,
        cart,
        needsOpenCart,
        setNeedsOpenCart,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

export default AccountProvider
