import { useState } from 'react'
import { INotification } from '~/components/Generic/CustomNotification'
import CustomNotificationContext from '~/context/custom-notification'

const CustomNotificationProvider = ({ children }) => {
  const [showNotification, setShowNotification] = useState(false)
  const [onCloseFn, setOnCloseFn] = useState<() => void>(null)
  const [notification, setNotification] = useState<INotification>({
    type: 'success',
    modalType: 'modal',
    timer: 5000,
    message: '',
  })

  const handleShowNotification = (showNotification) => {
    setShowNotification(showNotification)
    if (!showNotification) {
      if (onCloseFn) onCloseFn()
    }
  }

  return (
    <CustomNotificationContext.Provider
      value={{
        showNotification,
        setShowNotification: handleShowNotification,
        notification,
        setNotification,
        onCloseNotification: setOnCloseFn,
      }}
    >
      {children}
    </CustomNotificationContext.Provider>
  )
}

export default CustomNotificationProvider
