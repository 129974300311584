export const parseDateNumber = (num: number, doubleZero = false) => {
  if (num === 0 && !doubleZero) {
    return num
  }
  return num < 10 ? `0${num}` : num
}

export const calculateTimeLeft = (date: Date) => {
  const difference = +date - +new Date()

  if (difference > 0) {
    const timeLeft = {
      D: parseDateNumber(Math.floor(difference / (1000 * 60 * 60 * 24))),
      H: parseDateNumber(Math.floor((difference / (1000 * 60 * 60)) % 24)),
      M: parseDateNumber(Math.floor((difference / 1000 / 60) % 60)),
      S: parseDateNumber(Math.floor((difference / 1000) % 60)),
    }

    return `${timeLeft.D} Days   ${timeLeft.H} Hours   ${timeLeft.M} Minutes   ${timeLeft.S} Seconds`
  }
}

export const calculateTimeLeftScrollingText = (date: Date) => {
  const difference = +date - +new Date()

  if (difference > 0) {
    const timeLeft = {
      D: parseDateNumber(Math.floor(difference / (1000 * 60 * 60 * 24)), true),
      H: parseDateNumber(
        Math.floor((difference / (1000 * 60 * 60)) % 24),
        true
      ),
      M: parseDateNumber(Math.floor((difference / 1000 / 60) % 60), true),
      S: parseDateNumber(Math.floor((difference / 1000) % 60), true),
    }

    return `${timeLeft.D}:${timeLeft.H}:${timeLeft.M}:${timeLeft.S}`
  }
}
