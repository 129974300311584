/** @jsxImportSource theme-ui */
import React from 'react'
import Link, { LinkProps } from '~/components/Generic/Link'
import styles from './styles.module.css'

type Props = Omit<LinkProps, 'children' | 'href'> & {
  page: {
    name: string
    slug: string
  }
  hasHero?: boolean | null
  loggedIn?: boolean | false
}

const NavMenuDesktopLink: React.FC<Props> = ({ page, hasHero }) => {
  return (
    <Link
      href={`/${page.slug}/`}
      className={styles.links}
      sx={{
        color: !hasHero
          ? 'var(--black-one-off) !important'
          : 'var(--white-one-off) !important',
      }}
    >
      {page.name}
    </Link>
  )
}

export default NavMenuDesktopLink
