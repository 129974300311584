/** @jsxImportSource theme-ui */
import React, { useEffect, useState } from 'react'
import { Box, Flex, Spinner } from 'theme-ui'
import theme from '~/theme'
import { useTranslate } from '~/hooks'
import styles from './styles.module.css'
import { useCheckout } from '@chordcommerce/react-autonomy'
import Loading from '~/components/Loading'
import ResponsiveImage from '~/components/Generic/Image'
import { IImage, IProductAsset } from 'graphql-cms/types'
import { toUsdCurrency } from '~/utils'
import { loadStripe } from '@stripe/stripe-js'
import CartIcon from '~/assets/images/icons/cart.svg'
import { useCartContext } from '~/context/cart'

interface Props {
  hasHero?: boolean
  productsAssets?: IProductAsset[]
}

interface ILineItem {
  lineItemId: number
  name: string
  sku: string
  price: string
  image: IImage | false
}

const stripePromise = loadStripe(process.env.STRIPE_PK_KEY, {
  stripeAccount: process.env.STRIPE_CONNECTED_ACCOUNT,
})

const DrawerCart: React.FC<Props> = ({
  hasHero = true,
  productsAssets = [],
}) => {
  const translate = useTranslate()
  const { needsOpenCart, cart, removeFromCart, loadCart } = useCartContext()
  const [qty, setQty] = useState(cart.totalQuantity)
  const [showCart, setShowCart] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [itemsInCart, setItemsInCart] = useState<ILineItem[]>([])
  const color = !hasHero
    ? `${theme.colors.black} !important`
    : `${theme.colors.white} !important`
  const { prepareCheckout } = useCheckout()

  const handleRemoveFromCart = async (lineItemId: number) => {
    try {
      document.getElementById(`remove_${lineItemId}`).style.display = 'none'
      document.getElementById(`spinner_${lineItemId}`).style.display = 'block'
      await removeFromCart({ lineItemId })
      document.getElementById(`remove_${lineItemId}`).style.display = 'block'
      document.getElementById(`spinner_${lineItemId}`).style.display = 'none'
    } catch (error) {
      console.log(error)
    }
  }

  const updateFormatedItems = async () => {
    const { lineItems: _lineItems } = cart
    const formatedItems = _lineItems?.map((item) => {
      const {
        id: lineItemId,
        variant: { id: itemId, slug, name, price: _price, sku, images },
      } = item
      const price = toUsdCurrency(parseInt(_price))
      const asset =
        Array.isArray(productsAssets) &&
        productsAssets.filter((asset) => asset.slug === slug).shift()
      const miniUrl = asset?.mainImage?.url || images?.shift()?.miniUrl || null

      const image = miniUrl
        ? ({
            __typename: 'Asset',
            sys: {
              id: `${itemId}`,
            },
            contentType: 'image/png',
            title: name,
            url: miniUrl,
            width: 1540,
            height: 680,
          } as IImage)
        : false

      return {
        lineItemId,
        name,
        sku,
        price,
        image,
      }
    }) as ILineItem[]

    setQty(formatedItems?.length || 0)
    setItemsInCart(formatedItems || [])
  }

  const openDrawerCart = async () => {
    setIsLoading(true)
    setShowCart(!showCart)
    setIsLoading(false)
  }

  const handlePrepareCheckout: React.FormEventHandler<HTMLElement> = async (
    event
  ) => {
    if (event) event.preventDefault()

    try {
      const _cart = await prepareCheckout()
      const stripe = await stripePromise
      stripe.redirectToCheckout({
        sessionId: _cart.checkoutSessionId,
      })
    } catch (error) {
      console.log(error)
    }

    return
  }

  useEffect(() => {
    loadCart()
      .then((cart) => {
        setQty(cart?.totalQuantity || 0)
      })
      .catch((error) => {
        console.log(error.message)
      })
  }, [loadCart])

  useEffect(() => {
    updateFormatedItems()
    if (!showCart && needsOpenCart) openDrawerCart()
  }, [cart])

  return (
    <>
      <span
        className={styles.showCardBtn}
        sx={{ color }}
        onClick={openDrawerCart}
      >
        <Flex sx={{ marginRight: '8px' }}>
          <span
            sx={{
              display: ['block', 'none'],
              '& svg': {
                width: '30px',
                height: '30px',
                filter: [hasHero ? 'invert(1)' : '', 'none'],
              },
            }}
          >
            <CartIcon />
          </span>
          <span
            sx={{
              display: ['none', 'block'],
              fontFamily:
                qty > 0
                  ? 'Mint Grotesk Display'
                  : 'Mint Grotesk Display Light !important',
            }}
          >
            {qty > 0
              ? translate('cart_qty', { qty })
              : translate('cart.page_title')}
          </span>
        </Flex>
      </span>
      <Box
        onClick={openDrawerCart}
        className={styles.boxCart}
        sx={{ display: showCart ? 'fixed' : 'none' }}
      >
        <Box
          onClick={(e) => e.stopPropagation()}
          sx={{
            borderRadius: ['none', '5px', '5px'],
            margin: ['none', '10px', '10px'],
            display: 'fixed',
            position: 'absolute',
            width: ['100%', '464px', '464px'],
            height: ['100%', '655px', '655px'],
            maxHeight: ['100%', '655px', '655px'],
            top: '0%',
            right: '0%',
            backgroundColor: 'white',
            padding: '30px',
            color: theme.colors.black,
          }}
        >
          {isLoading && <Loading />}
          {!isLoading && (
            <Flex
              sx={{
                flexFlow: 'column',
                width: '100%',
                height: '100%',
                justifyContent: 'space-between',
              }}
            >
              <Flex sx={{ flexFlow: 'column', width: '100%', height: '80%' }}>
                <Flex
                  sx={{
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingBottom: '9px',
                    borderBottom: `2px solid ${theme.colors.black}`,
                  }}
                >
                  <span className={styles.cartTitle}>
                    {qty > 0
                      ? translate('cart_qty', { qty })
                      : translate('cart.page_title')}
                  </span>
                  <span className={styles.cartClose} onClick={openDrawerCart}>
                    {translate('bid.close')}
                  </span>
                </Flex>

                <Flex className={styles.itemsContainer}>
                  {itemsInCart.map(
                    ({ lineItemId, sku, name, price, image }, key) => (
                      <Flex className={styles.itemsContainer_item} key={key}>
                        <Flex className={styles.itemsContainer_item_image}>
                          {image && (
                            <Box sx={{ width: '50%' }}>
                              <ResponsiveImage
                                image={image as IImage}
                                width={image.width}
                              />
                            </Box>
                          )}
                          {!image && `No Image`}
                        </Flex>
                        <Flex className={styles.itemsContainer_item_desc}>
                          <span>{name}</span>
                          <span>{price}</span>
                          <Flex className={styles.itemsContainer_item_act}>
                            <span>{translate('cart.product_id', { sku })}</span>
                            <span
                              id={`remove_${lineItemId}`}
                              onClick={() => handleRemoveFromCart(lineItemId)}
                            >
                              {translate('cart.remove_item')}
                            </span>
                            <span
                              id={`spinner_${lineItemId}`}
                              style={{ display: 'none' }}
                            >
                              <Spinner
                                data-testid="spinner"
                                size={15}
                                color="inherit"
                              />
                            </span>
                          </Flex>
                        </Flex>
                      </Flex>
                    )
                  )}
                </Flex>
              </Flex>

              <Flex
                sx={{
                  justifyContent: 'center',
                  flexFlow: 'column',
                  gap: 10,
                  height: 'auto',
                }}
              >
                <span
                  sx={{
                    color: theme.colors.grayMedium,
                    textAlign: 'center',
                    fontFamily: 'Mint Grotesk Display Light',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                  }}
                >
                  {translate('cart.shipping_costs')}
                </span>
                <span
                  onClick={handlePrepareCheckout}
                  sx={{
                    border: `1px solid ${theme.colors.black}`,
                    textAlign: 'center',
                    backgroundColor: theme.colors.black,
                    color: theme.colors.green,
                    fontFamily: 'Mint Grotesk Display Light',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                    padding: '10px',
                    cursor: 'pointer',
                    textTransform: 'uppercase',
                  }}
                >
                  {translate('cart.checkout')}
                </span>
              </Flex>
            </Flex>
          )}
        </Box>
      </Box>
    </>
  )
}

export default DrawerCart
