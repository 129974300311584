import { OrderBig, RemoveFromCart } from '@chordcommerce/react-autonomy'
import React from 'react'

export interface ICartContext {
  buyNow: (account: { sku: string, quantity: number }) => Promise<OrderBig>
  addToCart: (account: { sku: string, quantity: number }) => Promise<OrderBig>
  removeFromCart: (options: RemoveFromCart)  => Promise<OrderBig>
  loadCart: ()  => Promise<OrderBig>
  isLoaded: boolean
  isFetching: boolean
  cart: Partial<OrderBig>
  needsOpenCart: boolean
  setNeedsOpenCart: (needsOpenCart: boolean) => void
}

const CartContext = React.createContext<ICartContext | null>(null)
CartContext.displayName = 'CartContext'

export function useCartContext() {
  const context = React.useContext(CartContext)
  if (!context) {
    throw new Error(`useCartContext must be used within a CartProvider`)
  }
  return context
}

export default CartContext
