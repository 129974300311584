import React, { useEffect, useState } from 'react'
import { Box, Flex } from 'theme-ui'
import LogoError from '~/assets/images/icons/modal/toastError.svg'
import LogoSuccess from '~/assets/images/icons/modal/toastSuccess.svg'
import LogoWarning from '~/assets/images/icons/modal/warning.svg'
import { useTranslate } from '~/hooks'
import theme from '~/theme'
import styles from './styles.module.css'

interface Props {
  type: 'success' | 'error' | 'warning'
  timer: number
  message: string
  setNotification(notification: boolean): void
}

const globalConfig = {
  success: {
    icon: <LogoSuccess />,
    color: theme.colors.successToast,
  },
  error: {
    icon: <LogoError />,
    color: theme.colors.errorToast,
  },
  warning: {
    icon: <LogoWarning />,
    color: theme.colors.warningToast,
  },
}

export const Toast: React.FC<Props> = ({
  type,
  setNotification,
  timer,
  message,
}) => {
  const translate = useTranslate()
  const [autoClose, setAutoClose] = useState(null)
  const { icon, color } = globalConfig[type]

  useEffect(() => {
    if (!autoClose) {
      setAutoClose(setTimeout(() => setNotification(false), timer))
    }
  }, [timer, autoClose, setNotification, setAutoClose])

  return (
    <Flex className={styles.modal} sx={{ border: `1px solid ${color}` }}>
      <Flex sx={{ justifyContent: 'start', alignItems: 'center', gap: '20px' }}>
        <Box className={styles.modal_div_icon}>{icon}</Box>
        <span className={styles.modal_div_text} style={{ color }}>
          {message}
        </span>
      </Flex>
      <span
        onClick={() => setNotification(false)}
        className={styles.modal_close}
      >
        <span>{translate('bid.close')}</span>
      </span>
    </Flex>
  )
}

export default Toast
