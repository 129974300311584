import React from 'react'
import { INotification } from '~/components/Generic/CustomNotification'

export interface ICustomNotificationContext {
  showNotification: boolean
  setShowNotification: (show: boolean) => void
  notification: INotification
  setNotification: (notification: INotification) => void
  onCloseNotification: (fn: () => void) => void
}

const CustomNotificationContext =
  React.createContext<ICustomNotificationContext | null>(null)
CustomNotificationContext.displayName = 'CustomNotificationContext'

export function useCustomNotificationContext() {
  const context = React.useContext(CustomNotificationContext)
  if (!context) {
    throw new Error(`useAccount must be used within a AccountProvider`)
  }
  return context
}

export default CustomNotificationContext
