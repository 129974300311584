/** @jsxImportSource theme-ui */
import { useAuth } from '@chordcommerce/react-autonomy'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import Link from '~/components/Generic/Link'
import { useAccountContext } from '~/context/account'
import { useTranslate } from '~/hooks'

interface Props {
  hasHero: boolean
}

const AuthLink: React.FC<Props> = ({ hasHero = true }) => {
  const { isLoggedIn } = useAuth()
  const { account } = useAccountContext()
  const { defaultLocale } = useRouter()
  const translate = useTranslate()
  const [name, setName] = useState(translate('login.page_title'))
  const [urlButton, setUrlButton] = useState('/login')

  useEffect(() => {
    if (isLoggedIn && account) {
      const { firstName, handle } = account
      const newName = firstName
        ? firstName[defaultLocale]
        : handle
        ? handle[defaultLocale]
        : ''
      setName(translate('hello_user', { name: newName.toLocaleUpperCase() }))
      setUrlButton('/account')
    } else {
      setName(translate('login.page_title'))
      setUrlButton('/login')
    }
  }, [account, isLoggedIn])

  return (
    <Link
      href={urlButton}
      sx={{
        textTransform: 'uppercase',
        fontFamily: isLoggedIn
          ? 'Mint Grotesk Display'
          : 'Mint Grotesk Display Light',
        fontSize: '12px',
        fontStyle: 'normal',
        lineHeight: 'normal',
        color: !hasHero
          ? 'var(--black-one-off) !important'
          : 'var(--white-one-off) !important',
      }}
    >
      {name}
    </Link>
  )
}

export default AuthLink
