import { PRODUCT_FRAGMENT } from 'graphql-cms/fragments'
import type {
  IProduct,
  IRelatedProduct,
  ICatalogItem,
  IProductAsset,
} from 'graphql-cms/types'
import { fetchGraphQL } from '../api'
import {
  MAIN_IMAGE_SLUG_FRAGMENT,
  SLIM_PRODUCT_FRAGMENT,
} from 'graphql-cms/fragments/product'

interface Query {
  productCollection: {
    items: IProduct[] | null
  }
}

export const getAllProducts = async (): Promise<IProduct[]> => {
  const query = `
    query {
      productCollection(where: { slug_exists: true }) {
        items {
          ${SLIM_PRODUCT_FRAGMENT}
        }
      }
    }
  `

  const response = await fetchGraphQL<Query>(query)

  const products = response.data.productCollection.items
    ? response.data.productCollection.items
    : []

  return products
}

export const getProducts = async (): Promise<IProduct[]> => {
  const query = `
    query {
      productCollection(where: { slug_exists: true }) {
        items {
          ${PRODUCT_FRAGMENT}
        }
      }
    }
  `

  const response = await fetchGraphQL<Query>(query)

  const products = response.data.productCollection.items
    ? response.data.productCollection.items
    : []

  // @TODO (kit-deprecation) remove this nonesense once we are all in on
  // bundles
  for (let i = 0; i < products.length; i++) {
    products[i].relatedProductsCollection.items =
      products[i].relatedProductsCollection?.items?.filter((product) =>
        removeKitsFromRelatedProducts(product)
      ) ?? []
  }

  return products
}

export const getProduct = async ({
  slug,
}: {
  slug: string
}): Promise<IProduct> => {
  const query = `
    query GetProduct($slug: String!) {
      productCollection(where: { slug: $slug }, limit: 1)  {
        items {
          ${PRODUCT_FRAGMENT}
        }
      }
    }
  `

  const response = await fetchGraphQL<Query>(query, { slug })
  const product = response.data.productCollection?.items?.[0]

  if (!product) {
    throw new Error(`No product found for slug: ${slug}`)
  }

  // @TODO (kit-deprecation) remove this nonesense once we are all in on
  // bundles
  product.relatedProductsCollection.items =
    product.relatedProductsCollection?.items?.filter((product) =>
      removeKitsFromRelatedProducts(product)
    ) ?? []

  return product
}

export const getMainImageAllProducts = async (): Promise<IProductAsset[]> => {
  const query = `
    query {
      productCollection(where: { slug_exists: true }) {
        items {
          ${MAIN_IMAGE_SLUG_FRAGMENT}
        }
      }
    }
  `

  const response = await fetchGraphQL<Query>(query)

  const products = response.data.productCollection.items
    ? response.data.productCollection.items
    : []

  return products
}

interface SlugQuery {
  productCollection: {
    items: {
      slug: string
    }[]
  }
}

export const getAllProductSlugs = async (): Promise<string[]> => {
  const query = `
    query GetProductSlugs {
      productCollection(where: { slug_exists: true }) {
        items {
          slug
        }
      }
    }
  `

  const response = await fetchGraphQL<SlugQuery>(query)

  if (!response.data.productCollection.items) {
    return []
  }

  return response.data.productCollection.items.map(({ slug }) => slug)
}

export const getProductsByBastaId = async ({
  bastaIdList,
}: {
  bastaIdList: string[]
}): Promise<IProduct[]> => {
  const query = `
    query getProductsByBastaId($bastaIdList: [String]) {
      productCollection(where: { bastaId_in: $bastaIdList })  {
        items {
          name
          bastaId
          slug
          mainImage{
            url
          }
        }
      }
    }
  `

  const response = await fetchGraphQL<Query>(query, { bastaIdList })
  const product = response.data.productCollection?.items

  if (!product) {
    throw new Error(`No product found for slug: ${JSON.stringify(bastaIdList)}`)
  }

  return product
}

// @TODO (kit-deprecation) remove this nonesense once we are all in on
// bundles
export const removeKitsFromRelatedProducts = (
  product: IRelatedProduct | ICatalogItem
) =>
  // @ts-expect-error
  product.__typename !== 'Kit'
