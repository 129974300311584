import { IImage, IMAGE_FRAGMENT } from './image'
import { IProduct, SLIM_PRODUCT_FRAGMENT } from './product'

export const ARCHIVE_FRAGMENT = `
  title
  slug
  celebrityName
  dropStartDate
  dropEndDate
  image {
    ${IMAGE_FRAGMENT}
  }
  imageMobile {
    ${IMAGE_FRAGMENT}
  }
  bannerImage {
    ${IMAGE_FRAGMENT}
  }
  dropsListCollection(limit: 10) {
    items {
      ${SLIM_PRODUCT_FRAGMENT}
    }
  }
`

export const SLIM_ARCHIVE_FRAGMENT = `
  slug
  title 
  celebrityName
  dropStartDate
  dropEndDate
  image {
    ${IMAGE_FRAGMENT}
  }
  imageMobile {
    ${IMAGE_FRAGMENT}
  }
`

export interface ISlimArchive {
  title: string
  slug: string
  celebrityName?: string
  dropStartDate?: string
  dropEndDate?: string
  image: IImage
  imageMobile?: IImage
}

export interface IArchive {
  title: string
  slug: string
  celebrityName?: string
  dropStartDate?: string
  dropEndDate?: string
  image: IImage
  imageMobile?: IImage
  bannerImage: IImage
  dropsListCollection: {
    items: IProduct[]
  }
}
