/** @jsxImportSource theme-ui */
import React from 'react'
import { Flex } from 'theme-ui'
import NavMenuLogoBlack from './LogoBlack'
import styles from './styles.module.css'
import CloseNav from '~/assets/images/icons/closeNav.svg'
import theme from '~/theme'

interface Props {
  isOpen?: boolean
  setIsOpen
}

const NavMenuLinks: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  isOpen,
  setIsOpen,
}) => {
  return (
    <Flex
      sx={{
        width: '100%',
        height: '100%',
        position: 'fixed',
        top: '0%',
        left: '0%',
        right: '0%',
        bottom: '0%',
        padding: '18px 0px 0px 10px',
        margin: 0,
        flexDirection: 'column',
        display: isOpen ? 'flex' : 'none',
        backgroundColor: theme.colors.background,
        zIndex: 9,
        color: theme.colors.text,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        border: 0,
      }}
      className={styles.NavMenuLink}
    >
      <span
        sx={{
          right: '27px',
          top: '27px',
          position: 'absolute',
          width: '3%',
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <CloseNav />
      </span>
      <span
        sx={{
          paddingBottom: '59px !important',
        }}
      >
        <NavMenuLogoBlack />
      </span>
      {children}
    </Flex>
  )
}

export default NavMenuLinks
